define([], function () {
    'use strict';

    function isNavigatorAvailable() {
        return typeof window !== 'undefined' && typeof window.navigator !== 'undefined';
    }

    function isIpadIOS13() {
        if (!isNavigatorAvailable()) {
            return false;
        }

        return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream;
    }

    function isIOS13Device() {
        const isIphoneIOS13 = /iP(hone|od|ad).*OS 13/.test(navigator.userAgent);

        return isIpadIOS13() || isIphoneIOS13;
    }

    return {
        isIOS13Device,
        isIpadIOS13
    };
});
